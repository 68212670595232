<template>
    <AppContainer>
        <GalleryImages/>
    </AppContainer>
</template>

<script>
  import AppContainer from '@/components/containers/DashboardContainer.vue'
  import GalleryImages from '@/components/dashboard/gallery/Gallery.vue'
  export default {
    components: {
      AppContainer,
      GalleryImages
    }
  }
</script>