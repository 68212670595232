<template>
    <div class="lead-search-area">
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-4">
                <h3>Photo Gallery</h3>
            </div>
            <div class="col-sm-12 col-md-8 col-lg-8">
                <button
                type="button"
                class="button rem-btn float-right"
                data-bs-toggle="modal"
                data-bs-target="#addImageModal"
                >
                <fa class="mr-2" icon="plus" /> Add New Images
                </button>
            </div>
        </div>
    </div>

    <div class="leads-table-area addcustomer-table p-3">
        <div class="row" v-if="galleryImages.length > 0">
            <div
                class="col-lg-3 col-md-4 col-sm-6 col-12 text-center my-3"
                v-for="(gallery, index) in galleryImages"
                :key="index"
            >
                <div class="border p-1 box">
                <div class="links d-flex justify-content-between">
                    <div class="edit mb-1">
                    <img
                        class="img-fluid"
                        src="../../../assets/images/icons/pencil.svg"
                        title="Edit Title"
                        v-on:click="openEditModel(gallery, index)"
                    />
                    </div>
                    <div class="delete mb-1">
                    <img
                        class="img-fluid"
                        src="../../../assets/images/icons/Delete.svg"
                        title="Delete Image"
                        v-on:click="deleteImage(gallery.id, index)"
                    />
                    </div>
                    <!-- <div class="status mb-1">
                    <img
                        class="img-fluid"
                        src="../../../assets/images/icons/pencil.svg"
                        title="Active / InActive"
                    />
                    </div> -->
                </div>
                <img
                    :src="gallery.image"
                    :title="gallery.title"
                    class="img-fluid"
                    id="imgBox"
                />
                <h5 class="titleBox" :title="gallery.title">{{ gallery.title }}</h5>
                </div>
            </div>
        </div>
        <div class="row text-center mx-auto p-5" v-else>
            <div class="col-12">
                <img
                src="@/assets/images/dashboard/notifications.svg"
                style="width: 150px"
                />
                <h3>No Images</h3>
                <p>There are no images yet. Please add some imges to show here.</p>
            </div>
        </div>
    </div>

    <div class="modal fade" id="addImageModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <form
                v-on:submit.prevent="uploadImages"
                name="form"
                role="form"
                enctype="multipart/form-data"
                >
                <div class="modal-header border-bottom">
                    <h4 class="modal-title">Add new images</h4>
                    <button type="button" class="close" data-bs-dismiss="modal">
                    <span aria-hidden="true"
                        ><img
                        class="img-fluid"
                        src="@/assets/images/dashboard/close.svg"
                        alt=""
                    /></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                    <div class="col-12 p-3">
                        <input
                        type="file"
                        class="form-control input-lg"
                        accept="image/*"
                        v-on:change="selectImages"
                        multiple
                        />
                    </div>
                    <div
                        class="col-lg-3 col-md-4 col-sm-6 text-center pb-3 mt-3"
                        v-for="(path, index) in files_path"
                        :key="index"
                    >
                        <div>
                        <span
                            class="remove_selection"
                            v-on:click="removeImage(index)"
                            title="Remove Image"
                            ><fa icon="times"
                        /></span>
                        <img
                            :src="path"
                            class="img-fluid"
                            style="width: 175px; height: 150px"
                        />
                        </div>
                        <div class="input-group pt-2">
                        <input
                            name="title"
                            v-model="imgTitle[index]"
                            type="text"
                            class="form-control"
                            placeholder="Image title"
                        />
                        </div>
                    </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="ond-btn-w" data-bs-dismiss="modal">
                    Close
                    </button>
                    <button type="submit" class="ond-btn-p">
                    <span v-if="!btnLoader">Upload</span>
                    <span v-if="btnLoader"
                        >Uploading... &nbsp;&nbsp;<fa class="ml-2" icon="refresh spin"
                    /></span>
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>

    <div id="editTitleModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header">
                <h4 class="modal-title">Edit Title</h4>
                <button type="button" class="close" data-bs-dismiss="modal">
                    <span aria-hidden="true"
                    ><img
                        class="img-fluid"
                        src="@/assets/images/dashboard/close.svg"
                        alt=""
                    /></span>
                </button>
                </div>
                <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12 text-center pb-3">
                    <textarea
                        v-model="editGallery_.title"
                        placeholder="Enter Title"
                        rows="3"
                        class="w-100"
                    ></textarea>
                    </div>
                </div>
                </div>
                <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-default pull-right"
                    data-bs-dismiss="modal"
                >
                    Close
                </button>
                <input
                    type="submit"
                    class="btn btn-success pull-right"
                    value="Update"
                    ng-click="editTitle()"
                    v-on:click="updateImageTitle()"
                />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { useToast } from "vue-toastification";
import axios from "axios";
import $ from "jquery";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      btnLoader: false,
      form_data: [],
      files: [],
      imgTitle: [],
      files_path: [],
      galleryImages: [],
      editGallery_: {
        id: "",
        business_id:
          this.$storage.getStorageSync("business").active_business.id,
        title: "",
        index: "",
      },
    };
  },
  created() {
    this.getGalleryImages();
  },
  methods: {
    getGalleryImages() {
      let thiss = this;
      axios
        .post("gallery_images", {
          id: thiss.$storage.getStorageSync("business").active_business.id,
        })
        .then(function (response) {
          thiss.galleryImages = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            this.toast.error(error.response.data.message);
          }
        });
    },
    selectImages(element) {
      let thiss = this;
      this.files = $.merge(this.files, element.target.files);
      this.files_path = [];

      for (var j = 0; j < this.files.length; j++) {
        var file = this.files[j];
        var reader = new FileReader();
        reader.onload = function (e) {
          thiss.files_path.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
      console.log(this.files_path);
    },
    removeImage(index) {
      this.files_path.splice(index, 1);
      this.imgTitle.splice(index, 1);
      this.files.splice(index, 1);
    },
    uploadImages() {
      if (this.files.length > 0) {
        let thiss = this;
        thiss.btnLoader = true;

        axios({
          method: "post",
          url: "upload_gallery_images",
          transformRequest: [
            function () {
              var formData = new FormData();
              console.log(thiss.files);
              formData.append(
                "id",
                thiss.$storage.getStorageSync("business").active_business.id
              );
              thiss.files.forEach((value, key) => {
                console.log(value);
                formData.append("images[]", value);
                formData.append("title[]", thiss.imgTitle[key]);
              });
              return formData;
            },
          ],
          data: thiss.form_data,
        })
          .then(function (response) {
            console.log(response.data);
            if (response.data.status == "success") {
              $("#addImageModal").modal("hide");
              thiss.form = [];
              thiss.files = [];
              thiss.imgTitle = [];
              thiss.getGalleryImages();
            }
            thiss.btnLoader = false;
          })
          .catch((error) => {
            if (error.response) {
              this.toast.error(error.response.data.message);
            }
          });
      } else {
        this.toast.error("Please select some images");
      }
    },
    openEditModel(gallery, i) {
      $("#editTitleModal").modal("show");
      this.editGallery_.id = gallery.id;
      this.editGallery_.title = gallery.title;
      this.editGallery_.index = i;
      console.log(this.editGallery_.title);
    },
    updateImageTitle() {
      // console.log(editGallery_);
      let thiss = this;
      axios
        // console.log(this.editGallery_.title);
        .post("update_image_title", thiss.editGallery_)
        .then(function (response) {
          if (response.data.status == "success") {
            thiss.galleryImages[thiss.editGallery_.index].title =
              thiss.editGallery_.title;
            $("#editTitleModal").modal("hide");
            //thiss.galleryImages.splice(i, 1);
            thiss.toast.success(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            thiss.toast.error(error.response.data.message);
          }
        });
    },
    deleteImage(id, i) {
      let thiss = this;
      this.$swal({
        title: "Are you sure?",
        text: "You want to delete this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "rem-btn",
          cancelButton: "add-btn mr-3",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("delete_gallery_image", {
              id: id,
              business_id:
                thiss.$storage.getStorageSync("business").active_business.id,
            })
            .then(function (response) {
              if (response.data.status == "success") {
                thiss.galleryImages.splice(i, 1);
                thiss.toast.success(response.data.message);
              }
              //   console.log(response.data);
              //   thiss.getGalleryImages();
              //   thiss.toast.success(response.data.message);
            })
            .catch((error) => {
              if (error.response) {
                thiss.toast.error(error.response.data.message);
              }
            });
        }
      });
    },
  },
};
</script>
<style type="text/css">
    .remove_selection {
        position: absolute;
        right: 8%;
        top: 0;
        cursor: pointer;
        background: #ffffff;
        width: 25px;
        border-radius: 50px;
        border: 1px solid black;
    }
    .edit,
    .delete,
    .status {
        background-color: #fbe8fc;
        width: 30px;
        border-radius: 50px;
        height: 30px;
        padding-top: 5px;
        cursor: pointer;
    }
    .box {
        height: 250px;
    }
    .titleBox {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
    }
    #imgBox {
        width: 260px;
        height: 150px;
        object-fit: fill;
    }
</style>